<template>
  <div class="home-warp">
    <div class="banner-wrap">
      <swiper class="banner-swiper" ref="bannerSwiper" :options="swiperOptions">
        <swiper-slide v-for="banner in banners" :key="banner.name">
          <!-- <img :data-src="banner.imgUrl" class="banner-img swiper-lazy" /> -->
          <div class="banner-img" :style="{backgroundImage: 'url(' + banner.imgUrl + ')'}"></div>
          <!-- <img :data-src="banner.imgUrl" class="banner-img swiper-lazy" /> -->
          <!-- <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div> -->
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="search-bar-wrap">
      <SearchBar
      v-model="searchObj"
      @search="onSearch"/>
      <div class="search-bar-bg bg-1"></div>
      <div class="search-bar-bg bg-2"></div>
    </div>
    <Recommand @query="onRecommandQuery"/>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SearchBar from '@/components/SearchBar'
import Recommand from './components/Recommand'

import { queryToUrlParams } from '@/utils/queryFlight'

import moment from 'moment'

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
    SearchBar,
    Recommand
  },
  data () {
    return {
      swiperOptions: {
        height: 520,
        lazy: {
          loadPrevNext: true
        },
        pagination: {
          el: '.swiper-pagination'
        },
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        }
      },
      banners: [
        {
          name: 'banner1',
          imgUrl: require('../../assets/images/banner-1.jpg')
        },
        {
          name: 'banner2',
          imgUrl: require('../../assets/images/banner-2.jpg')
        },
        {
          name: 'banner3',
          imgUrl: require('../../assets/images/banner-3.jpg')
        }
      ],
      searchObj: {
        type: 'RT',
        flights: [
          {
            depCity: '',
            arrCity: '',
            depDate: moment().add(1, 'd').format('YYYY-MM-DD')
          },
          {
            depCity: '',
            arrCity: '',
            depDate: moment().add(2, 'd').format('YYYY-MM-DD')
          }
        ]
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.bannerSwiper.$swiper
    }
  },
  mounted () {
    // this.swiper.slideTo(0, 1000, false)
  },
  methods: {
    onSearch (query) {
      this.$router.push({
        path: '/book',
        query: { query: queryToUrlParams(query) }
      })
    },
    onRecommandQuery (query) {
      this.onSearch(query)
    }
  }
}
</script>

<style lang="less" scoped>
.home-warp {
  margin-top: -100px;
}

.banner-swiper {
  height: 520px;

  .banner-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
}

.search-bar-wrap {
  min-height: 300px;
  position: relative;
  z-index: 100;
  margin-top: -60px;

  .search-bar {
    margin: 0 auto;
    position: relative;
    z-index: 100;
  }

  ::v-deep .search-box {
      box-shadow: 0px 13px 26px rgba(0, 0, 0, 0.12);
  }
}

.search-bar-bg {
  &.bg-1 {
    width: 330px;
    height: 130px;
    background: url('~@/assets/images/home-bg-l.png') no-repeat center;
    position: absolute;
    left: 40px;
    top: 128px;
  }

  &.bg-2 {
    width: 330px;
    height: 130px;
    background: url('~@/assets/images/home-bg-r.png') no-repeat center;
    position: absolute;
    right: 40px;
    top: 87px;
  }
}
</style>
