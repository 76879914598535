<template>
    <div class="recommand">
      <div class="recommand-head">
        <span>竞拍</span>
    </div>
      <div class="recommad-main">
          <div class="recommand-tab">
              <div class="recommand-tab-head">
              <div class="_tab-head-item"
                v-for="(tab, index) in recommandList"
                :key="index"
                :class="{active: curTabIndex === index}"
                @click="onTabClick(index)"
                >{{ tab.title | airCity(true) }}</div>
              </div>
              <div class="recommand-tab-box">
                  <div class="recommand-btn-prev">
                    <a-icon type="caret-left" />
                  </div>
                  <div class="recommand-swiper-wrap">
                    <swiper class="recommand-swiper" ref="recommandSwiper" :options="swiperOptions">
                        <template v-if="recommands.length">
                        <swiper-slide  v-for="(item, index) in recommands" :key="index">
                            <div class="rcmd-flight" @click="toSearch(item)">
                                <div class="flight-direct">
                                    <div class="direct-dep">{{ item.depCode | airCity(true) }}</div>
                                    <div class="direct-icon">
                                      <a-icon v-if="item.tripType === 'RT'" class="success-icon" type="swap" />
                                      <a-icon v-if="item.tripType === 'ST'" class="success-icon" type="arrow-right" />
                                    </div>
                                    <div class="direct-arr">{{ item.arrCode | airCity(true) }}</div>
                                </div>
                                <div class="flight-cross">
                                    <div class="flight-line"></div>
                                    <div class="flight-icon-wrap">
                                      <div class="flight-icon"></div>
                                    </div>
                                </div>
                                <div class="flight-info">
                                    <div class="fligt-price">
                                      <small>参考价格:</small>
                                      <small>￥</small>
                                      <span>{{ item.fare }}</span>
                                      </div>
                                    <div class="flight-date">{{ item.fltDateStart | date }}-{{ item.fltDateEnd | date }}</div>
                                </div>
                            </div>
                        </swiper-slide>
                        </template>
                    </swiper>
                  </div>
                  <div class="recommand-btn-next">
                      <a-icon type="caret-right" />
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import moment from 'moment'
import { recommend } from '@/api/notice'
import { airCity } from '@/filters/flight'

export default {
  name: 'Recommand',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 5,
        height: 180,
        navigation: {
          nextEl: '.recommand-btn-next',
          prevEl: '.recommand-btn-prev'
        }
      },
      recommandList: [],
      curTabIndex: 0
    }
  },
  computed: {
    recommands () {
      return this.recommandList[this.curTabIndex] ? this.recommandList[this.curTabIndex].list : []
    },
    swiper () {
      return this.$refs.recommandSwiper.$swiper
    }
  },
  created () {
    this.fetch()
  },
  mounted () {
    // this.swiper.slideTo(3, 1000, false)
  },
  methods: {
    async fetch () {
      try {
        this.recommandList = await recommend()
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    onTabClick (index) {
      this.curTabIndex = index
    },
    toSearch (item) {
      const flights = [
        {
          depCity: item.depCode,
          arrCity: item.arrCode,
          depDate: moment(item.fltDateStart).format('YYYY-MM-DD')
        }
      ]

      if (item.tripType !== 'ST') {
        flights.push({
          depCity: item.arrCode,
          arrCity: item.depCode,
          depDate: moment(item.fltDateStart).add(1, 'd').format('YYYY-MM-DD')
        })
      }

      const query = {
        type: item.tripType,
        flights
      }

      this.$emit('query', query)
    }
  },
  filters: {
    date (date) {
      return moment(date).format('MM.DD')
    },
    airCity
  }
}
</script>

<style lang="less" scoped>
.recommand{

}
.recommand-head{
    padding: 0 40px 20px 40px;

    span {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        border-left: 2px solid @joy-orange-color;
        font-size: 24px;
        padding-left: 20px;
    }
}
.recommad-main{

}
.recommand-tab{

}
.recommand-tab-head{
    background: #f3f3f3;
    padding: 0 40px;
    width: 100%;
    overflow: hidden;

    ._tab-head-item{
      cursor: pointer;
      display: block;
      float: left;
      font-size: 14px;
      min-width: 160px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-top: 2px solid #f3f3f3;
      transition: all 0.3s;

      &.active {
          font-weight: bold;
          background: #fff;
          color: @joy-orange-color;
          border-top: 2px solid @joy-orange-color;
      }
    }
}

.recommand-tab-box {
    position: relative;
    margin: 0 40px;
    height: 180px;
    box-sizing: border-box;

}

.recommand-btn-next,
.recommand-btn-prev {
    position: absolute;
    top: 0;
    left: 0;
    height: 180px;
    width: 42px;
    background: @joy-darkblue-color;
    z-index: 10;
    transition: all 0.3s;
    text-align: center;

    &:hover {
        background: @joy-orange-color;
    }
    .anticon {
      color: #fff;
      font-size: 20px;
      line-height: 180px;
    }
}
.recommand-btn-next {
    left: auto;
    right: 0;
}
.recommand-swiper-wrap {
    height: 180px;
    padding: 0 40px;
    position: relative;
}

.rcmd-flight {
    padding: 0 40px;
    cursor: pointer;

    .flight-direct {
        font-size: 14px;
        color: #666;
        width: 186px;
        margin-top: 40px;
        display: table;
        width: 100%;
        line-height: 30px;

        div  {
            display: table-cell;
        }

        .direct-dep,
        .direct-arr {
          width: 30%
        }
        .direct-icon {
          width: 40%;
          text-align:center
        }

        .direct-arr {
          text-align: right;
        }
    }

    .flight-cross {
        line-height: 50px;
        height: 50px;
        position: relative;
    }

    .flight-line {
        display: inline-block;
        height: 1px;
        margin-right: 10px;
        background: @joy-orange-color;
        width: 100%;
        vertical-align: middle;
    }

    .flight-icon-wrap {
      position: absolute;
      top: 15px;
      right: 0;
      line-height: normal;
      height: 23px;
      width: 43px;
      text-align: center;
      background: #fff
    }

    .flight-icon {
        display: inline-block;
        vertical-align: middle;
        height: 23px;
        width: 23px;
        background: url('~@/assets/images/feiji.png') no-repeat center;
    }
    .flight-info {
        display: table;
        width: 100%;
    }
    .fligt-price {
        display: table-cell;
        font-size: 20px;
        color: @joy-orange-color;
        font-weight: bold;

        small {
            font-size: 12px;
            font-weight: normal;
        }

    }
    .flight-date {
        display: table-cell;
        font-size: 12px;
        color: @joy-darkblue-color;
        text-align: right;
    }
}

</style>
